export const storageSet = (key, value) => {
  useCookie(
    key,
    {
      default: () => (value),
      watch: false
    }
  )  
  useState(key).value = value
}

export const storageGet = (key) => {
  const item = useState(key)
  if (item.value === undefined || item.value === null) {
    item.value = useCookie(key).value
  }
  return item
}

export const storageRemove = (key) => {
  const item = useState(key)
  item.value = null
  useCookie(key).value = null
}

export const isAuthenticated = () => {
  return apiFetch('/user/login_status?_format=json')
}


export const fetchWithCookie = async (url: string) => {
  const res = await $fetch.raw(url)
  const cookies = (res.headers.get('set-cookie') || '').split(',')
  for (const cookie of cookies) {
    appendHeader(useRequestEvent(), 'set-cookie', cookie)
  }
  return res._data
}

export const apiFetch = (url: string, params?: any) => {
  const runtimeConfig = useRuntimeConfig()
  let options = { key: url, ...params }
  if (params?.cookie) {
    options.headers = useRequestHeaders(['cookie'])
    delete options?.cookie
  }
  const api = $fetch.create({ baseURL: runtimeConfig.public.API_BASE_URL })
  // Todo refactor to handle error code
  const data = api(url, {
    headers: {
      ...useRequestHeaders(['cookie']),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    key: url,
    ...options,
  })
    .then((data) => {
      return {
        data: ref(data),
      }
    })
    .catch((error) => {
      return {
        data: ref(),
      }
    })
  return data
}

export const apiMutate = (url: string, body, options?: any) => {
  const { $crsfToken: getCrsfToken } = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()
  const api = $fetch.create({ baseURL: runtimeConfig.public.API_BASE_URL })
  const id = body.data?.id || options?.id
  const method = id ? 'PATCH' : 'POST'
  const endpoint = id ? url + '/' + id : url

  return getCrsfToken().then((crsfToken) => {
    let headers = {
      ...useRequestHeaders(['cookie']),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': crsfToken.value,
    }
    if (options?.headers) {
      headers = { ...headers, ...options.headers }
      delete options.headers
    }
    return api(endpoint, {
      headers,
      body,
      method,
      key: url,
      ...options,
    }).catch((error) => error.data)
  })
}

export const apiClientFetch = (endpoint: string, options?: any) => {
  const runtimeConfig = useRuntimeConfig()
  const api = $fetch.create({ baseURL: runtimeConfig.public.API_BASE_URL })

  return api(endpoint, { method: 'GET', key: endpoint, ...options })
}

export const buildUrl = (base: string, params: any) => {
  const runtimeConfig = useRuntimeConfig()
  let url = new URL(runtimeConfig.public.API_BASE_URL + base)
  for (let key in params) {
    url.searchParams.append(key, params[key])
  }
  return url.toString()
}

export default defineNuxtPlugin(async (nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error) {
    return {}
  }
  const session = useState('user')
  const loggedIn: any = computed(() => !!session.value?.email)

  const refresh = async () => {
    const { data: authUser } = await apiFetch('/api/users', { cookie: true })
    session.value = authUser?.value?.[0]
    if (!session.value) {
      storageRemove('csrf_token')
      storageRemove('logout_token')
      storageRemove('user')
      const { data: anonUser } = await apiFetch('/jsonapi/user/user', {
        pick: ['data'],
      })
      session.value = {
        uid: 0,
        id: anonUser?.value?.data?.[0].id,
        name: 'Anonymous',
      }
    }
  }
  refresh()

  // Create a ref to know where to redirect the user when logged in
  const redirectTo = useState('authRedirect')
  const currentRoute = useRoute()

  if (process.client) {
    watch(loggedIn, async (loggedIn) => {
      if (!loggedIn && currentRoute.meta.auth) {
        redirectTo.value = currentRoute.path
        await navigateTo('/login')
      }
    })
  }

  if (loggedIn.value && currentRoute.path === '/login') {
    await navigateTo(redirectTo.value || '/')
  }

  return {
    provide: {
      currentUser: () => {
        return session
      },
      refreshUser: () => {
        refresh()
      },
      auth: {
        loggedIn,
        session,
        redirectTo,
      },
    },
  }
})


interface PDFPage {
  id: string
  title: string
  metadata?: Record<string, unknown>
}

interface PDFOptions {
  filename?: string
  margin?: number
  quality?: number
  pageSize?: 'a4'
  orientation?: 'portrait' | 'landscape'
}

interface PDFGeneratorOptions extends PDFOptions {
  onProgress?: (progress: number) => void
  onError?: (error: Error) => void
}

class PDFGeneratorService {
  private getDefaultOptions(options?: PDFOptions) {
    return {
      margin: options?.margin ?? 10,
      filename: options?.filename ?? 'document.pdf',
      image: { type: 'jpeg', quality: options?.quality ?? 0.98 },
      html2canvas: {
        scale: 2,
        useCORS: true,
        letterRendering: true,
      },
      jsPDF: {
        unit: 'mm',
        format: options?.pageSize ?? 'a4',
        orientation: options?.orientation ?? 'portrait',
      },
      pagebreak: { mode: 'css' },
    }
  }

  async generatePDF(
    pages: PDFPage[],
    options?: PDFGeneratorOptions
  ): Promise<void> {
    try {
      const container = document.createElement('div')

      pages.forEach((page, index) => {
        const element = document.getElementById(page.id)
        if (element) {
          const clone = element.cloneNode(true) as HTMLElement
          if (index < pages.length - 1) {
            clone.style.pageBreakAfter = 'always'
          }
          container.appendChild(clone)
        }
      })

      const pdfOptions = this.getDefaultOptions(options)

    } catch (error) {
      const err =
        error instanceof Error ? error : new Error('PDF generation failed')
      options?.onError?.(err)
      throw err
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
    const pdfGenerator = new PDFGeneratorService()

    return {
      provide: {
        pdfGenerator: (pages: PDFPage[], options?: PDFGeneratorOptions) =>
          pdfGenerator.generatePDF(pages, options),
      },
    }
})
export default defineNuxtPlugin(() => {
  return {
    provide: {
      crsfToken: async () => {
        const crsfToken = useState('csrf_token')
        if (!crsfToken.value) {
          const {data} = await apiFetch("/session/token", {cookie: true})
          crsfToken.value = data.value
          useCookie('csrf_token').value = data.value
        }
        return crsfToken
      }
    }
  }
})